import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const modernisme = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Modernisme" />
    <h3 className='underline-title'>Modernisme</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        C'est Picasso et ses tableaux<br />
        C'est poisson qui vole <br />
        C'est tortue qui galope <br />
      </p>
      <p>
        C'est aussi...<br />
        UN femme en pantalon <br />
        UNE homme en hauts talons <br />
        Une souris qui miaule<br />
      </p>
      <p>
        Modernisme signifie également<br />
        UNE garçon en grossesse<br />
        UN fille polyandre<br />
        Un chat caquetant<br />
      </p>
      <p>
        Modernisme?<br />
        C'est le pape polygame en pagne <br />
        Un pygmée pontife en tenue d'astronaute <br />
        Une antilope dînant chez son chasseur <br />
        Wamunga-le-chasseur qui broute <br />
      </p>
      <p>
        Enfin...<br />
        il est moderne que <br />
        <span className="par-starting"></span>la secheresse pleuve<br />
        <span className="par-starting"></span>la pluie chauffe <br />
        <span className="par-starting"></span>la nuit éclaire<br />
        <span className="par-starting"></span>le jour obscurcisse.<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default modernisme
